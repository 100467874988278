// RefundPolicy.js
import React from 'react';

const RefundPolicy = () => {
     return (
          <div className="refund-policy">
               <h1>Политика возврата</h1>
               <p>Мы стремимся предоставить качественные товары и высокий уровень обслуживания. Если вы по какой-либо причине не удовлетворены покупкой, ознакомьтесь с нашей Политикой возврата.</p>
               <h2>1. Условия возврата</h2>
               <p>Возврат товара возможен в течение 14 дней с момента покупки при условии, что товар не был использован и сохранена его оригинальная упаковка.</p>
               <h2>2. Возврат средств</h2>
               <p>Возврат средств производится в течение 10 рабочих дней после получения нами товара и его проверки. Возврат средств осуществляется тем же способом, которым была произведена оплата.</p>
               <h2>3. Исключения</h2>
               <p>Возврат средств не предусмотрен для товаров, использованных по назначению или с поврежденной упаковкой, за исключением случаев обнаружения заводского брака.</p>
               <h2>4. Связь с нами</h2>
               <p>Если у вас возникли вопросы, пожалуйста, свяжитесь с нашей службой поддержки по электронной почте: support@вашсайт.com.</p>
          </div>


     );
};

export default RefundPolicy;
