const SliderData = [
     {
          img: require("../assets/Дизайн без названия (1).png")
     },
   

     {
          img: require("../assets/Дизайн без названия (5).png")
     },
     {
          img: require("../assets/Дизайн без названия.png")
     }

     
  
]
export default SliderData