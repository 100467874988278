// TermsOfService.js
import React from 'react';

const TermsOfService = () => {
     return (
          <div className="terms">
               <h1>Условия использования</h1>
               <p>Добро пожаловать на наш сайт по продаже музыкальных инструментов. Пожалуйста, ознакомьтесь с настоящими Условиями использования перед тем, как воспользоваться нашими услугами. Используя наш сайт, вы соглашаетесь с данными Условиями.</p>
               <h2>1. Общие положения</h2>
               <p>Мы предоставляем вам доступ к нашему сайту для покупки музыкальных инструментов и сопутствующих товаров. Все данные, предоставляемые на сайте, предназначены только для личного использования.</p>
               <h2>2. Заказы и оплата</h2>
               <p>При оформлении заказа вы соглашаетесь предоставить точную и актуальную информацию. Оплата за товар должна быть произведена в момент покупки. Мы принимаем платежи с помощью кредитных карт, криптовалют и других методов, доступных на нашем сайте.</p>
               <h2>3. Доставка</h2>
               <p>Доставка осуществляется в соответствии с условиями, указанными на сайте. Пожалуйста, проверьте данные перед завершением заказа.</p>
               <h2>4. Ограничение ответственности</h2>
               <p>Мы не несем ответственность за прямые или косвенные убытки, возникшие в результате использования нашего сайта или покупок, совершенных через него.</p>
               <h2>5. Изменение условий</h2>
               <p>Мы оставляем за собой право изменять данные Условия использования в любое время без предварительного уведомления.</p>
          </div>

     );
};

export default TermsOfService;
